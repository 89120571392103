import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '../store';

Vue.use(VueRouter);

const routes = [
  //**************** HOME AREA ****************//
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/layouts/Auth'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('../views/auth/Login'),
        meta: {
          requiresAuth: false
        },
      },
      {
        path: '/confirm/:token',
        name: 'confirm_auth',
        component: () => import('../views/auth/Confirm'),
        meta: {
          requiresAuth: false
        },
      },
    ]
  },
  //**************** SHARE AREA ****************//
  {
    path: '/share',
    name: 'Share',
    component: () => import('../components/layouts/Auth'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'file/:hash',
        name: 'shareFile',
        component: () => import('../views/share/File'),
        meta: {
          requiresAuth: false
        },
      },
      {
        path: 'folder/:hash',
        name: 'shareFolder',
        component: () => import('../views/share/Folder'),
        meta: {
          requiresAuth: false
        },
      },
    ]
  },
  //**************** TZ AREA ****************//
  {
    path: '/tz/:hash',
    name: 'Tz',
    component: () => import('../views/tz/View'),
    meta: {
      requiresAuth: false
    },
  },
  //**************** PRIVATE AREA ****************//
  {
    path: '/my',
    component: () => import('../components/layouts/Private'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'feed',
        component: () => import('../views/my/Feed'),
      },
      //**************** PROJECT AREA ****************//
      {
        path: 'project/:uid',
        name: 'project',
        component: () => import('../components/navigation/Project'),
        children: [
          {
            path: 'dashboard',
            name: 'projectDashboard',
            component: () => import('../views/my/project/Dashboard'),
          },
          {
            path: 'tasks',
            name: 'projectTasks',
            component: () => import('../views/my/project/tasks/List'),
          },
          {
            path: 'tasks/detail/:hash',
            name: 'projectTasksDetail',
            component: () => import('../views/my/project/tasks/Detail'),
          },
          {
            path: 'tasks/new',
            name: 'projectTasksNew',
            component: () => import('../views/my/project/tasks/Create'),
          },
          {
            path: 'telegram',
            name: 'projectChat',
            component: () => import('../views/my/project/telegram/List'),
          },
          {
            path: 'chat_detail/:id',
            name: 'chatDetail',
            component: () => import('../views/my/project/telegram/Chat'),
          },
          {
            path: 'team',
            name: 'projectTeam',
            component: () => import('../views/my/project/team/List'),
          },
          {
            path: 'team_add',
            name: 'newTeamItem',
            component: () => import('../views/my/project/team/Add'),
          },
          {
            path: 'budget',
            name: 'projectBudget',
            component: () => import('../views/my/project/Budget'),
          },
          {
            path: 'docs',
            name: 'projectDocs',
            component: () => import('../views/my/project/Docs'),
          },
          {
              path: 'events',
              name: 'projectEvents',
              component: () => import('../views/my/project/events/List'),
          },
          {
            path: 'event_add',
            name: 'newEventItem',
            component: () => import('../views/my/project/events/Add'),
          },
          {
            path: 'alerts',
            name: 'projectRemark',
            component: () => import('../views/my/project/alerts/List'),
          },
          {
            path: 'alert_add',
            name: 'newAlertIem',
            component: () => import('../views/my/project/alerts/Add'),
          },
          {
            path: 'stages',
            name: 'projectStages',
            component: () => import('../views/my/project/kanban/Customers.vue'),
          },
          {
            path: 'stages/board/:id',
            name: 'projectStagesBoard',
            component: () => import('../views/my/project/kanban/Board.vue'),
          },
          {
            path: 'compass',
            name: 'projectCompass',
            component: () => import('../views/my/project/compass/List'),
          },
          {
            path: 'compass/detail/:hash',
            name: 'projectCompassDetail',
            component: () => import('../views/my/project/compass/Detail'),
          },
        ]
      },
      //**************** REPORTS AREA ****************//
      {
        path: 'reports',
        name: 'reportsList',
        component: () => import('../views/my/Reports/Overview'),
      },
      {
        path: 'reports/passports',
        name: 'reportsPassport',
        component: () => import('../views/my/Reports/Passports'),
      },
      {
        path: 'reports/passports/:id/detail',
        name: 'reportsPassportView',
        component: () => import('../views/my/Reports/ViewPassport'),
      },
      {
        path: 'reports/contracts',
        name: 'reportsContracts',
        component: () => import('../views/my/Reports/Contracts'),
      },
      {
        path: 'reports/withdrawal',
        name: 'reportsWithdrawal',
        component: () => import('../views/my/Reports/Withdrawal'),
      },
      {
        path: 'reports/withdrawal/:id/detail',
        name: 'reportsWithdrawalDetail',
        component: () => import('../views/my/Reports/Withdetail'),
      },
      {
        path: 'reports/pfr',
        name: 'reportsPfr',
        component: () => import('../views/my/Reports/Pfr'),
      },
      {
        path: 'reports/loans',
        name: 'reportsLoans',
        component: () => import('../views/my/Reports/Loans'),
      },
      {
        path: 'reports/loans/view/:id',
        name: 'reportsLoan',
        component: () => import('../views/my/Reports/Loan'),
      },
      {
        path: 'reports/projects',
        name: 'reportsProjects',
        component: () => import('../views/my/Reports/Projects'),
      },
      {
        path: 'reports/projects/add',
        name: 'addProject',
        component: () => import('../views/my/Reports/AddProject'),
      },
      {
        path: 'reports/projects/edit/:id',
        name: 'editProject',
        component: () => import('../views/my/Reports/EditProject'),
      },
      {
        path: 'reports/advances',
        name: 'reportAdvances',
        component: () => import('../views/my/Reports/Advances'),
      },
      {
        path: 'reports/advances/view/:id',
        name: 'advanceDetail',
        component: () => import('../views/my/Reports/ViewAdvance'),
      },
      {
        path: 'reports/customers',
        name: 'reportCustomers',
        component: () => import('../views/my/Reports/Customers'),
      },
      {
        path: 'reports/customers/view/:id',
        name: 'customerDetail',
        component: () => import('../views/my/Reports/CustomerDetail'),
      },
      {
        path: 'reports/customers/add',
        name: 'addCustomer',
        component: () => import('../views/my/Reports/AddCustomer'),
      },
      {
        path: 'reports/ads',
        name: 'ads',
        component: () => import('../views/my/Reports/AdsView'),
      },
      {
        path: 'reports/subscribe',
        name: 'reportSubscribe',
        component: () => import('../views/my/Reports/Subscribe'),
      },
      {
        path: 'reports/subscribe/add',
        name: 'reportAddSubscribe',
        component: () => import('../views/my/Reports/AddSubscribe'),
      },
      {
        path: 'reports/subscribe/view/:id',
        name: 'editSupportContract',
        component: () => import('../views/my/Reports/SupportDetail'),
      },
      //**************** PEOPLE AREA ****************//
      {
        path: 'people',
        name: 'peopleList',
        component: () => import('../views/my/People/Overview'),
      },
      {
        path: 'people/:id/detail',
        name: 'peopleProfile',
        component: () => import('../views/my/People/Detail'),
      },
      //**************** ACCOUNT AREA ****************//
      {
        path: 'account',
        component: () => import('../views/my/Account/Overview'),
        children: [
          {
            path: 'profile',
            name: 'accountProfile',
            component: () => import('../views/my/Account/Profile'),
            meta: { active_tab: 0 }
          },
          {
            path: 'verification',
            name: 'accountVerification',
            component: () => import('../views/my/Account/Verification'),
            meta: { active_tab: 1 }
          },
          {
            path: 'edit_verify',
            name: 'verifyEdit',
            component: () => import('../views/my/Account/VerifyEdit'),
            meta: { active_tab: 1 }
          },
          {
            path: 'edit_verify/bank',
            name: 'verifyEditBank',
            component: () => import('../views/my/Account/VerifyEditBank'),
            meta: { active_tab: 1 }
          },
          {
            path: 'contacts',
            name: 'accountContacts',
            component: () => import('../views/my/Account/Contacts'),
            meta: { active_tab: 2 }
          },
          {
            path: 'password',
            name: 'accountPassword',
            component: () => import('../views/my/Account/Password'),
            meta: { active_tab: 3 }
          },
          {
            path: 'identification',
            name: 'accountIdentification',
            component: () => import('../views/my/Account/Identification'),
            meta: { active_tab: 3 }
          },
          {
            path: 'skills',
            name: 'accountSkills',
            component: () => import('../views/my/Account/Skills'),
            meta: { active_tab: 4 }
          },
        ]
      },
      //**************** MONEY AREA ****************//
      {
        path: 'money',
        name: 'moneyList',
        component: () => import('../views/my/Money/Overview'),
      },
      {
        path: 'money/:hash/wallet',
        name: 'moneyWallet',
        component: () => import('../views/my/Money/Wallet'),
      },
      //**************** TASKS AREA ****************//
      {
        path: 'tasks',
        name: 'tasksList',
        component: () => import('../views/my/Tasks/List'),
      },
      {
        path: 'tasks/:hash/detail',
        name: 'taskDetail',
        component: () => import('../views/my/Tasks/Detail'),
      },
      //**************** LEADS AREA ****************//
        /*
      {
        path: 'leads',
        name: 'leadsList',
        component: () => import('../views/my/Leads/Overview'),
      },
      {
        path: 'leads/:id/detail',
        name: 'leadDetail',
        component: () => import('../views/my/Leads/Detail'),
      },
      {
        path: 'leads/create',
        name: 'leadCreate',
        component: () => import('../views/my/Leads/Create'),
      },

         */
      //**************** SALES AREA ****************//
      {
        path: 'sales',
        name: 'salesList',
        component: () => import('../views/my/Sales/SalesList.vue'),
      },
      {
        path: 'sales/:id/detail',
        name: 'salesCard',
        component: () => import('../views/my/Sales/SaleCard.vue'),
      },
      //**************** HELP AREA ****************//
      {
        path: 'help',
        name: 'helpList',
        component: () => import('../views/my/Help/Overview'),
      },
      {
        path: 'help/manager',
        name: 'helpManager',
        component: () => import('../views/my/Help/Manager'),
      },
      {
        path: 'help/:id/editor',
        name: 'helpEditor',
        component: () => import('../views/my/Help/Editor'),
      },
      {
        path: 'help/:id/post',
        name: 'helpPost',
        component: () => import('../views/my/Help/Post'),
      },
      {
        path: 'help/:id/posts',
        name: 'helpPosts',
        component: () => import('../views/my/Help/Posts'),
      },
      //**************** DRIVE AREA ****************//
      {
        path: 'drive',
        name: 'driveList',
        component: () => import('../views/my/Drive/Overview'),
      },
      //**************** SUPPORT AREA ****************//
      {
        path: 'support/inbox',
        name: 'supportInbox',
        component: () => import('../views/my/Support/Inbox'),
      },
      {
        path: 'support/pending',
        name: 'supportPending',
        component: () => import('../views/my/Support/Pending'),
      },
      {
        path: 'support/settings',
        name: 'supportSettings',
        component: () => import('../views/my/Support/Settings'),
      },
      {
        path: 'support/settings/departments',
        name: 'supportDepartments',
        component: () => import('../views/my/Support/Departments'),
      },
      {
        path: 'support/settings/types',
        name: 'supportTypes',
        component: () => import('../views/my/Support/Types'),
      },
      {
        path: 'support/settings/report',
        name: 'supportReport',
        component: () => import('../views/my/Support/Report'),
      },
      {
        path: 'support/settings/queues',
        name: 'supportQueues',
        component: () => import('../views/my/Support/Queues'),
      },
      {
        path: 'support/settings/first_line',
        name: 'supportFirstLine',
        component: () => import('../views/my/Support/FirstLine'),
      },
      {
        path: 'support/add',
        name: 'supportAdd',
        component: () => import('../views/my/Support/Create'),
      },
      {
        path: 'support/ticket/:uid',
        name: 'supportTicket',
        component: () => import('../views/my/Support/Ticket'),
      },
      {
        path: 'support/listing/:department',
        name: 'supportListing',
        component: () => import('../views/my/Support/Listing'),
      },
      {
        path: 'support/chats/:department',
        name: 'supportChats',
        component: () => import('../views/my/Support/Chats'),
      },
      {
        path: 'support/chats/detail/:id',
        name: 'supportChatDetail',
        component: () => import('../views/my/Support/ChatDetail'),
      },
      {
        path: 'support/search',
        name: 'supportSearch',
        component: () => import('../views/my/Support/Search'),
      },
      //**************** Admin AREA ****************//
      {
        path: "admin",
        name: "adminList",
        component: () => import('../components/navigation/Admin'),
        children: [
          {
            path: 'global',
            name: 'adminGlobal',
            component: () => import('../views/my/Admin/Global'),
          },
          {
            path: 'skills',
            name: 'adminSkills',
            component: () => import('../views/my/Admin/Skills'),
          },
          {
            path: 'email_config',
            name: 'adminEmailConfig',
            component: () => import('../views/my/Admin/email/EmailConfig'),
          },
          {
            path: 'edit_email/:id',
            name: 'adminEditEmail',
            component: () => import('../views/my/Admin/email/EditEmail'),
          },
          {
            path: 'add_email',
            name: 'adminAddEmail',
            component: () => import('../views/my/Admin/email/NewEmail'),
          },
          {
            path: 'verify',
            name: 'adminVerify',
            component: () => import('../views/my/Admin/Verify'),
          },
          {
            path: 'templates',
            name: 'adminTemplates',
            component: () => import('../views/my/Admin/templates/Templates'),
          },
          {
            path: 'edit_template/:id',
            name: 'adminEditTemplate',
            component: () => import('../views/my/Admin/templates/Edit'),
          },
          {
            path: 'leads_products',
            name: 'adminLeadsProducts',
            component: () => import('../views/my/Admin/leads/Products'),
          },
          {
            path: 'leads_types',
            name: 'adminLeadsTypes',
            component: () => import('../views/my/Admin/leads/Types'),
          },
          {
            path: 'leads_source',
            name: 'adminLeadsSource',
            component: () => import('../views/my/Admin/leads/Source'),
          },
        ]
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (Store.state.user.status) {
      window.console.log('Access allowed');
      if (to.name === 'supportInbox' || to.name === 'supportDepartments' || to.name === 'supportSettings' || to.name === 'supportAdd' || to.name === 'supportTicket' || to.name === 'supportListing' || to.name === 'supportSearch' || to.name === 'supportTypes' || to.name === 'supportReport' || to.name === 'supportPending' || to.name === 'supportQueues' || to.name === 'supportFirstLine' || to.name === 'supportChats' || to.name === 'supportChatDetail') {
        if (Store.state.navigation === 'projects') {
          Store.commit('setNavigation', 'tickets');
        }
      } else {
        if (Store.state.navigation === 'tickets') {
          Store.commit('setNavigation', 'projects');
        }
      }
      next();
    } else {
      next('/');
      window.console.log('Access denied');
    }
  } else {
    next();
  }
});

export default router
