import Vue from 'vue'
import Vuex from 'vuex'
import Router from '../router'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: {
      status: false,
      code: 0,
      message: []
    },
    user: {
      id: 0,
      role: 0,
      email: '',
      name: {
        first: '',
        last: ''
      },
      avatar: {
        type: '',
        value: ''
      },
      auth: '',
      status: false,
      support: false,
      skill: 0,
    },
    nightMode: false,
    drawer: true,
    projectMenu: true,
    globalLoading: false,
    navigation: 'projects',
    tickets_nav: [],
    tickets_inbox: 0,
    tickets_pending: 0,
    support_sort: {
      deadline: {
        date: '',
        status: false
      },
      user: {
        id: 0,
        name: null,
        status: false
      },
      answer: 0
    },
    support_select: false,
    subscribe: {
      list: [],
      first_line: false
    },
    notification: 0,
    autoHide: false
  },
  mutations: {
    setError(state, data) {
      if (data.code === 401) {
       data.message = {error: 'Отказано в доступе! Требуется повторный вход! Код ошибки 401'};
      }
      if (data.code === 403) {
        data.message = {error: 'Отказано в доступе! Некорректный запрос или недостаточный уровень привилегий! Код ошибки 403'};
      }
      state.error = {
        status: data.status,
        code: data.code,
        message: data.message
      };
      if (data.code === 401) {
        Router.push({ name: 'login' });
        this.dispatch('logout');
      }
    },
    setUserSkillStatus(state, data) {
      state.user.skill = data
    },
    setUser(state, data) {
      state.user = data
    },
    setTicketsNav(state, data) {
      state.tickets_nav = data
    },
    setTicketsInbox(state, data) {
      state.tickets_inbox = data
    },
    setTicketsPending(state, data) {
      state.tickets_pending = data
    },
    setNavigation(state, data) {
      state.navigation = data;
    },
    setAvatar(state, data) {
      state.user.avatar = {
        type: 'link',
        value: data
      }
    },
    nightEnable(state, status) {
      state.nightMode = status
    },
    drawerEnable(state, status) {
      state.drawer = status
    },
    projectMenuEnable(state, status) {
      state.projectMenu = status
    },
    setGlobalLoading(state, status) {
      state.globalLoading = status;
    },
    setSupportSortingDeadline(state, sorting) {
      state.support_sort.deadline = sorting;
    },
    setSupportSortingUser(state, sorting) {
      state.support_sort.user = sorting;
    },
    setSupportSortingAnswer(state, sorting) {
      state.support_sort.answer = sorting;
    },
    refreshSupportSorting(state) {
      state.support_sort = {
        deadline: {
          date: '',
          status: false
        },
        user: {
          id: 0,
          name: null,
          status: false
        },
        answer: 0
      }
    },
    setSupportSelect(state, value) {
      state.support_select = value;
    },
    setSubscribeSupport(state, value) {
      state.subscribe = value;
    },
    setNotificationStatus(state,value) {
      state.notification = value;
    },
    setNotificationAutoHide(state,value) {
      state.autoHide = value;
    }
  },
  actions: {
    closeError({ commit }) {
      commit('setError', {
        data: {
          status: false,
          code: 0,
          message: []
        },
      });
    },
    refreshUserSupportSorting({ commit }) {
      commit('setSupportSortingUser', {
        user: {
          id: 0,
          name: null
        },
      });
    },
    logout({ commit }) {
      commit('setUser', {
        data: {
          id: 0,
          role: 0,
          email: '',
          name: {
            first: '',
            last: ''
          },
          avatar: {
            type: '',
            value: ''
          },
          auth: '',
          status: false
        },
      });
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      paths: ['user', 'nightMode', 'notification', 'autoHide']
    })
  ]
})
