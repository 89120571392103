import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import impelCss from './assets/css/impel.css';
import Editor from 'vue-editor-js';
import Notifications from 'vue-notification';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
//Vue.prototype.$serverApiLink = 'http://tazbo.ru.xsph.ru/';
Vue.prototype.$serverApiLink = 'http://apicrm.impeltech.ru/';

axios.interceptors.request.use(request => {
  request.headers['Authorization'] = store.state.user.auth;
  return request;
});

Vue.use(Editor);
Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  impelCss,
  render: h => h(App)
}).$mount('#app');
