import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Store from '../store'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: Store.state.nightMode,
        themes: {
            light: {
                navbar: '#1D1D2C',
                background: '#F5F9FC',
                primary: '#345DEE',
                success: '#1BBF84',
                warning: '#FF7E47',
                alert: '#FFAA00',
                danger: '#F44336',
                purple: '#6F52ED',
                info: '#48A7FF',
                main: '#ffffff',
                toolbar: '#ffffff',
                accent: '#5f85ff',
                panel: '#FFFFFF',
                business: '#A04E4E'
            },
            dark: {
                navbar: '#1D1D2C',
                background: '#373746',
                primary: '#5f85ff',
                success: '#1BBF84',
                warning: '#FF7E47',
                alert: '#FFAA00',
                danger: '#F44336',
                purple: '#6F52ED',
                info: '#48A7FF',
                main: '#ffffff',
                toolbar: '#272736',
                accent: '#5f85ff',
                panel: '#272736',
                business: '#A04E4E'
            },
        }
    }
});
