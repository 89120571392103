<template>
  <v-app>
    <v-overlay dark z-index="100" v-if="$store.state.globalLoading">
      <div class="text-center">
        <v-progress-circular
                indeterminate
                size="50"
                width="3"
                color="white"
        >
          <v-img
                  src="@/assets/img/mini-white-logo.svg"
                  max-width="30"
          />
        </v-progress-circular>
      </div>
    </v-overlay>
    <router-view/>
    <v-dialog
            v-model="$store.state.error.status"
            max-width="290"
            persistent
    >
      <v-card color="panel">
        <v-card-title class="red--text">Ошибка</v-card-title>
        <v-card-text
                v-for="(item,index) in $store.state.error.message"
                :key="'error_' + index"
                class="pb-1"
        >
          {{ item }}
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
                  color="primary"
                  text
                  @click="$store.dispatch('closeError')"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  name: 'App',
  data: () => ({
    pusher: undefined
  }),
  methods: {

  },
};
</script>
